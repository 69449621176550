import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/style-pages.css';
import { BsPerson } from "react-icons/bs";
import logoBrend from '../image/kotak.png'

const ProjectSyra = () => {

    return (
        <div>
            <div className="container pt-3 ">
                <img className='logoBrand' src={logoBrend} alt='Logo Brannd' />
                <div className='row justify-content-center'>
                    <h1 className='text-center mt-5'>SYRA ECOSYSTEM</h1>
                    <div className='row justify-content-center mt-5'>
                        <div onClick={() => window.open('https://drive.google.com/file/d/1fxnDe-5jR_SEYST-SnLr4Fpm6rWlwbqh/view?usp=drive_link ', '_blank')} className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mx-3 mb-5 border border-success conten '>
                            <div>
                                <BsPerson className='color-success' />
                                <p className='fw-bold mt-3 text-center'> SYRADIGITALIZE </p>
                                <p className='mt-3 text-center'> SYRADIGITALIZE is an innovative cryptocurrency token designed to create numerous job opportunities while transforming the realms of decentralized finance (DeFi), tokenization, and digitalization. Join us in this exciting venture to unlock new career pathways and empower the workforce of tomorrow!. See Whitepaper! </p>
                            </div>
                        </div>
                        <div onClick={() => window.open('#', '_blank')} className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mx-3 mb-5 border border-success conten '>
                            <div>
                                <BsPerson className='color-success' />
                                <p className='fw-bold mt-3 text-center'>SYRAFINANCE</p>
                                <p className='text-center'>SYRAFINANCE is under development. We will be back again soon</p>
                            </div>
                        </div>
                        <div onClick={() => window.open('https://azkolearning.com/', '_blank')} className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mx-3 mb-5 border border-success conten '>
                            <div>
                                <BsPerson className='color-success' />
                                <p className='fw-bold mt-3 text-center'>AZKO LEARNING</p>
                                <p className='text-center'>PT AZKO Maritim Bina Insani is an official Course and Training Institution (LPK) focused on enhancing the quality of Human Resources (HR). </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectSyra
